import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: "app-update-documents",
  templateUrl: "./update-documents.component.html",
  styleUrls: ["./update-documents.component.css"],
})
export class UpdateDocumentsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: false,
    showToolbar: false,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    toolbarPosition: "top",
    toolbarHiddenButtons: [[
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertUnorderedList',
      'insertOrderedList',
      'heading',
      'fontName'
    ],
    [
      'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
      'toggleEditorMode'
    ]],
  };

  selectedFiles : any[] = [];

  id : any = "";
  allDocs : any[] = [];
  docDescription : string = "";

  uploadDocs : any[] = [];
  showDocs = false;
  userDocs : any[] = [];
  adminDocs : any[] = [];
  mailSuccess = false;

  constructor(private activatedRoute : ActivatedRoute, private globalService : ServicesService) {
    this.activatedRoute.params.subscribe((val) => {
      this.id = this.activatedRoute.snapshot.params.id;
      this.getData(this.id);
    })
  }

  ngOnInit() {}

  getData(id){
    this.globalService.post('transaction/getDocs', {id : id},0).subscribe(res => {
      if (res) {

        this.allDocs = res.results[0].documents_data;
        this.docDescription = res.results[0].service_data[0].document_description;

        // if("docs_upload" in res.results[0]){

        //   console.log("res ", res.results[0].docs_upload);
        //   this.showDocs = true;
        //   this.uploadDocs = res.results[0].docs_upload;

        //   this.uploadDocs.forEach((item : any) => {
        //     if(item.uploaded_by == "user"){
        //       this.userDocs.push(...item.docs)
        //     }

        //     if(item.uploaded_by == "admin"){
        //       this.adminDocs.push(...item.docs)
        //     }
        //   })
        // }

      }
    });
  }

  selectFile(event, i){
    console.log("i is ", event.target.files);
    this.selectedFiles[i] = event.target.files;
  }

  onsubmit(){

    this.globalService.uploadDoc("transaction/addDocument", {id : this.id, docDescription : this.docDescription}, this.selectedFiles)
    .subscribe((data : any) => {
      console.log("data is ", data);
      if(!data.error){
        alert("Update success")
      }
      else{
        alert("Unable to set documents, please try again later.");
      }
    })
  }

  sendMail(email, phone){
    let postData = {
      email : email,
      phone : phone,
      contact : false
    }

    this.globalService.post("login/contact-us", postData, 0).subscribe((res) => {
      this.mailSuccess = true;
    })
  }


}
