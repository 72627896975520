import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-common-route',
  templateUrl: './common-route.component.html',
  styleUrls: ['./common-route.component.css']
})
export class CommonRouteComponent implements OnInit {

  businessServicesComponent: boolean = false;
  contactPageComponent: boolean = false;
  loginPageComponent: boolean = false;
  aboutPageComponent: boolean = false;
  orderSummeryPageComponent: boolean = false;
  thankuPageComponent: boolean = false;
  profilePageComponent: boolean = false;
  paymentGateway: boolean = false;
  updateDocument: boolean = false;
  invoicePage: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.businessServicesComponent  = false;
        this.contactPageComponent = false;
        this.loginPageComponent = false;
        this.aboutPageComponent = false;
        this.orderSummeryPageComponent = false;
        this.thankuPageComponent = false;
        this.profilePageComponent = false;
        this.paymentGateway = false;
        this.updateDocument = false;
        this.invoicePage = false;

        this.ngOnInit();
      }
    });

  }

  ngOnInit() { 

    if ((this.router.url).includes('/contact')) {
      this.contactPageComponent = true;
    }  
    else if ((this.router.url).includes('/login')) {
      this.loginPageComponent = true;
    }
    else if ((this.router.url).includes('/order-summary')) {
      this.orderSummeryPageComponent = true;
    }
    else if ((this.router.url).includes('/about')) {
      this.aboutPageComponent = true;
    }
    else if ((this.router.url).includes('/thanku')) {
      this.thankuPageComponent = true;
    }
    else if ((this.router.url).includes('/profile')) {
      this.profilePageComponent = true;
    }
    else if ((this.router.url).includes('/payment-gateway')) {
      this.paymentGateway = true;
    }
    else if ((this.router.url).includes('/update-documents')) {
      this.updateDocument = true;
    }
    else if ((this.router.url).includes('/invoice')) {
      this.invoicePage = true;
    }
     else{
      this.businessServicesComponent = true;
    }

  }

}
