import { BrowserModule } from '@angular/platform-browser';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServicesService } from './services.service';
import { AppRoutingModule } from './app-routing.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BusinessServicePlansComponent } from './pages/business-service-plans/business-service-plans.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { ThankuComponent } from './pages/thanku/thanku.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonRouteComponent } from './pages/common-route/common-route.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { PaymentGatewayComponent } from './pages/payment-gateway/payment-gateway.component';
import { UpdateDocumentsComponent } from './pages/update-documents/update-documents.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { DocsDetailsComponent } from './docs-details/docs-details.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AboutComponent,
    ContactComponent,
    BusinessServicePlansComponent,
    OrderSummaryComponent,
    ThankuComponent,
    ProfileComponent,
    CommonRouteComponent,
    PaymentGatewayComponent,
    UpdateDocumentsComponent,
    InvoiceComponent,
    DocsDetailsComponent,
    PrivacyComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    HttpClientModule,
    AppRoutingModule,
    AngularEditorModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule
  ],
  providers: [
    ServicesService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
