import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { SeoService } from 'src/app/seo.service';


@Component({
  selector: 'app-business-service-plans',
  templateUrl: './business-service-plans.component.html',
  styleUrls: ['./business-service-plans.component.css']
})

export class BusinessServicePlansComponent implements OnInit {
  servicesPlan: any;
  currentURL: any;
  serviceDetails: any;
  priceBox: any;
  otplogin: any;
  subTotelPrice: any;
  subTotelPrices: any;
  mailSuccess : boolean = false;

  constructor(
    private globalService: ServicesService,
    private seoService : SeoService,
    private router: Router,) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.service();

      }
    });

  }

  ngOnInit() {
    this.service();
  }


  service() {

    const url = {
      seo_url: this.router.url
    }

    this.globalService.post('addservices/get-service-data', url, 0).subscribe(res => {
      if (res) {
        this.serviceDetails = res.service;

        // if(res.service[0].title_tag){
        //   this.seoService.updateTitle(res.service[0].title_tag);
        // }

        // if(res.service[0].meta_tag){
        //   this.seoService.updateMetaTag(res.service[0].meta_tag);
        // }

        // if(res.service[0].meta_des){
        //   this.seoService.updateDescription(res.service[0].meta_des);
        // }

        this.subTotelPrice = res.service[0].priceData;

        this.subTotelPrice.forEach((value, index) => {
          const totalPrice = Number(value.price) + Number(value.stamp_duty) + Number(value.dsc_price) + Number(value.govt_price);
          value.totalPrice = totalPrice;
        });

        this.serviceDetails.priceData = this.subTotelPrice;

        this.globalService.SEOService(res.service[0]);
      }
    });
  }

  loginRoute(_id, documents_status, value, service_name, stamp_duty, dsc_price, name, govt_price, gst, price) {

    var url = [encodeURI(value)];

    this.otplogin = localStorage.getItem("otpLogin");

    let params = {
      service_id: _id,
      service_name : service_name,
      user_id : localStorage.getItem('userId'),
      documents_status: documents_status,
      plan_name: name,
      details : [
        {
          name : "Stamp Duty",
          price : stamp_duty,
          is_taxable : false
        },
        {
          name : "DSC",
          price : dsc_price,
          is_taxable : true
        },
        {
          name : "Govt. Fee",
          price : govt_price,
          is_taxable : false
        },
        {
          name : "Professional fee",
          price : price,
          is_taxable : true
        }
      ],
      gst: gst,
    };

    this.globalService.post("orders", params, 0).subscribe((res : any) => {

      let orderId = res.data._id;

      if (this.otplogin) {
        this.router.navigate(['/order-summary'], { queryParams:  {orderId}});
      }
      else {
        alert('Please login !!');
        this.router.navigate(['/login']);
      }
    });

  }

  sendMail(email, phone){
    let postData = {
      email : email,
      phone : phone,
      contact : false
    }

    this.globalService.post("login/contact-us", postData, 0).subscribe((res) => {
      this.mailSuccess = true;
    })
  }

}
