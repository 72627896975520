import { Component, ChangeDetectorRef, OnInit, NgZone } from "@angular/core";
import { ServicesService } from "src/app/services.service";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Validators } from "@angular/forms";
declare var $: any;
import { ExternalLibraryService } from "../payment";
import { of } from "rxjs";
import { Button } from "protractor";
declare var Razorpay: any;

@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  styleUrls: ["./order-summary.component.css"],
})
export class OrderSummaryComponent implements OnInit {
  serviceDetails: any;
  price: any;
  name: any;
  service_name: any;
  govt_price: any;
  dsc_price: any;
  stamp_duty: any;
  gst: any;
  totelPrice: any;
  private _id: any;
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  otplogin: any;
  userId: any;
  submitted = false;

  // name = 'Angular';
  response;
  razorpay: any;
  razorpayResponse;
  showModal = false;
  show = true;
  checked = false;

  isDisabled = true;
  showGSTField = false;
  stateLists: any;
  gstPrice: any;
  gstPrice1: number;
  gstTotel: any;
  subtotelPrice: any;
  gstPrice2: number;
  gstPrice3: number;
  delhiUser: any;
  documents_status: any;

  orderId : any;

  constructor(
    private globalService: ServicesService,
    private razorpayService: ExternalLibraryService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.userId = localStorage.getItem("userId");
        // this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.urlParams();
    this.userId = localStorage.getItem("userId");
    this.stateList();

    // this.razorpayService
    //   .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
    //   .subscribe();
  }

  stateList() {
    this.globalService.get("add-state/list", 0).subscribe((res) => {
      if (res) {
        this.stateLists = res.stateList;
      }
    });
  }

  checkfun(event) {
    this.showGSTField = event.target.checked;
  }

  urlParams() {

    this.activatedRoute.queryParams.subscribe((params) => {
      // this.documents_status = params.documents_status;
      this.orderId = params.orderId;

      this.globalService.post('orders/getData', { id : params.orderId }, 0).subscribe((res) => {
        console.log("data is ", res);

        let mData = res.data[0];

        this._id = mData.service_id;
        this.service_name = mData.service_name;
        this.name = mData.plan_name;

        for(let i=0; i<mData.details.length; i++){
          let det = mData.details[i];

          switch(det.name){
            case "Professional fee":
              this.price = det.price || 0;
              break;
            case "Govt. Fee":
              this.govt_price = det.price;
              break;
            case "DSC":
              this.dsc_price = det.price;
              break;
            case "Stamp Duty":
              this.stamp_duty = det.price;
              break;
          }
        }

        this.subtotelPrice =
        parseFloat(this.price || "0") +
        parseFloat(this.govt_price || "0") +
        parseFloat(this.dsc_price || "0") +
        parseFloat(this.stamp_duty || "0");

        let gstTotalPrice = parseFloat(this.price) || 0 + parseFloat(this.dsc_price) || 0;

        console.log("parseFloat(this.price) + parseFloat(this.dsc_price)", parseFloat(this.price) , parseFloat(this.dsc_price))

        console.log("gstTotalPrice ", gstTotalPrice)

        this.gstPrice1 = (gstTotalPrice * 18) / 100;
        this.gstTotel = this.gstPrice1;

        this.gstPrice2 = (gstTotalPrice * 9) / 100;
        this.gstPrice3 = (gstTotalPrice * 9) / 100;

        this.totelPrice = this.gstTotel + this.subtotelPrice;
        this.totelPrice = this.gstPrice2 + this.gstPrice3 + this.subtotelPrice;

          // this.gst = params.gst;
          // this.documents_status = params.documents_status;
        })

    });
  }

  sateName(value) {
    if (value === "Delhi") {
      this.delhiUser = true;
    }
    else {
      this.delhiUser = false;
    }
  }

  odersummaryForm = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      Validators.pattern("[a-zA-Z_ -]*"),
    ]),
    email: new FormControl("", [Validators.required, Validators.email]),
    address: new FormControl("", Validators.required),
    city: new FormControl("", [
      Validators.required,
      Validators.pattern("[a-zA-Z_ -]*"),
    ]),
    state: new FormControl("", [
      Validators.required,
      Validators.pattern("[a-zA-Z_ -]*"),
    ]),
    pincode: new FormControl("", [
      Validators.required,
      Validators.pattern("[0-9]{6,6}"),
    ]),
    gst_number: new FormControl("", []),
  });

  get f() {
    return this.odersummaryForm.controls;
  }

  //  ===================================Payment API ===========

  // test: rzp_test_y8Ow7ADa6SHjH5
  // RGMTpMi2OJ0IvzfIMyyksPMj
  RAZORPAY_OPTIONS = {
    key: "rzp_live_C2DSkJlGtwX6OI",
    amount: "",
    name: "Razorpay",
    // order_id: "",
    description: "Razorpay Wallet",
    image:
      "https://pbs.twimg.com/profile_images/1271385506505347074/QIc_CCEg_400x400.jpg",
    prefill: {
      name: "",
      email: "",
      contact: "",
      method: "",
    },
    modal: {},
    theme: {
      color: "#00b7db",
    },
  };

  public onSubmit() {
    this.submitted = true;
    if (this.odersummaryForm.invalid) {
      return;
    }

    // this.RAZORPAY_OPTIONS.amount = "100.00";
    this.RAZORPAY_OPTIONS.amount = this.totelPrice * 100 + "";

    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);

    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);

    razorpay.open();
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
    localStorage.setItem("paymentId", response.razorpay_payment_id);
    this.paymentApi();

    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
    this.cd.detectChanges()
    // document.getElementById('razorpay-response').style.display = 'block';
  }

  public test() {
    document.getElementById("response-modal").style.display = "block";
    this.response = `dummy text`;
  }

  paymentApi() {
    if (this.odersummaryForm.value.state === "Delhi") {
      this.gstPrice2;
      this.gstPrice3;
    }
    else {
      this.gstTotel;
    }

    const formData = this.odersummaryForm.value;
    formData.services_id = this._id;
    formData.user_id = localStorage.getItem("userId");
    formData.totel_price = this.totelPrice;
    formData.payment_id = localStorage.getItem("paymentId");
    formData.service_data = {
      govt_price : this.govt_price,
      dsc_price  : this.dsc_price ,
      stamp_duty : this.stamp_duty,
    }

    this.ngZone.run(() => {
      this.globalService.post('transaction', formData, 0).subscribe(res => {

        if (res) {
          if (res.ResponseCode == 1) {

            let orderInfo = {
              id : this.orderId,
              transaction_id : res.Result.createdService._id,
              igst : this.gstPrice1,
              sgst : this.gstPrice2,
              cgst : this.gstPrice3
            }

            this.globalService.post('orders/update', orderInfo, 0).subscribe((res : any) => {
              console.log("order updated");
            });

            localStorage.setItem('documents_status', this.documents_status);
            localStorage.setItem('trans_id', res.Result.createdService._id);
            this.odersummaryForm.reset();
            this.router.navigate(['/thanku']);
          }
          else {
            alert('Please try Again !!');
          }
        }

      });
    });
  }
}
