import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  mailSuccess = false;

  constructor(private globalService : ServicesService) { }

  ngOnInit() {
  }

  sendMail(fname, sname, email, phone, message){

    let postData = {
      email : email,
      phone : phone,
      name : fname + " " + sname,
      message : message,
      contact : true
    }

    this.globalService.post("login/contact-us", postData, 0).subscribe((res) => {
      this.mailSuccess = true;
    })
  }

}
