import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
declare var jQuery: any;
import { jsPDF } from "jspdf";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userId: any;
  userProfiledata: any;
  user_id: any;
  userProfiletransaction: any;
  submitted = false;
  invoiceDetails: any;
  orderTotal: any;
  orderNumber: any;
  fileToUpload: File;
  orderCreated_at: any;
  planDetails: any;
  doc_path: any = '';
  planName: any;
  invoiceDate: number;
  productsOfPlan: any;
  mainPrice: number;
  orders: any;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imageURL: any;

  uploadDocs : any[] = [];
  showDocs = false;
  userDocs : any[] = [];
  adminDocs : any[] = [];

  constructor(
    private globalService: ServicesService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private router: Router) {

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.userProfile();
    //   }
    // });
  }

  ngOnInit() {
    this.userProfile();
    this.getProfileImage();
  }

  getInvoice(value) {
    this.router.navigate(['invoice', value]);
  }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    const file = (fileInput.target as HTMLInputElement).files[0];
    // File Preview
    const reader = new FileReader();
    reader.readAsDataURL(file)
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.fileToUpload = fileInput.target.files.item(0);
    }
    this.globalService.postFile(this.fileToUpload)
      .subscribe(
        (result: any) => {
          this.doc_path = result.data;
        },
        err => {
        }
      );
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.imageURL = reader.result;
    }
  }

  getProfileImage() {
    const id = localStorage.getItem('userId');
    this.globalService.getProfileImage(id).subscribe(
      data => {
        if (data) {
          let file: any = data;
          const reader = new FileReader();
          reader.onload = () => {
            let str = reader.result as string;
            this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(str);
          }
          reader.readAsDataURL(file)
        }
      }
    )
  }

  profileForm = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10,10}')]),
    name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z_ -]*')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    image: new FormControl('',)
  })

  get f() { return this.profileForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('file', this.fileData);

    const data = {
      _id: localStorage.getItem("userId"),
      name: this.profileForm.value.name,
      number: this.profileForm.value.number,
      email: this.profileForm.value.email,
      image: this.doc_path
    }

    this.globalService.post('login/update-user-profile', data, 0).subscribe(res => {
      if (res) {
        this.profileForm.reset();
        jQuery('#editProfileModal').modal('hide');
        this.userProfile();
      }
    });

  }

  userProfile() {
    this.user_id = localStorage.getItem("userId");
    this.globalService.get('login/get-user-profile/' + this.user_id, 0).subscribe(res => {
      if (res) {
        console.log(res);
        this.userProfiledata = res.userProfile[0]._id;

        localStorage.setItem("user_name", res.userProfile[0]._id.name);
        this.userProfiletransaction = res.userProfile[0].transaction_data;

        this.userProfiletransaction = this.userProfiletransaction.reverse();


        this.profileForm.patchValue({
          name: this.userProfiledata.name,
          number: this.userProfiledata.number,
          email: this.userProfiledata.email
        });

      }

    });

  }

  logout() {
    var txt;
    if (confirm("Are you sure you want to Logout now !!")) {
      localStorage.clear();
      this.globalService.changeLogin(false);
      this.router.navigate(['/']);
    }
    else
    {
      txt = "You pressed Cancel!";
    }
  }

  openDocDialog(id){
    this.userDocs = [];
    this.adminDocs = [];

    this.globalService.post('transaction/getDocs', {id : id},0).subscribe(res => {
      if (res) {

        if("docs_upload" in res.results[0]){

          this.showDocs = true;
          this.uploadDocs = res.results[0].docs_upload;

          this.uploadDocs.forEach((item : any) => {
            if(item.uploaded_by == "user"){
              this.userDocs.push(...item.docs)
            }

            if(item.uploaded_by == "admin"){
              this.adminDocs.push(...item.docs)
            }
          })
        }

      }
    });
  }

}
