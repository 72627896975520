import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../services.service';

@Component({
  selector: 'app-docs-details',
  templateUrl: './docs-details.component.html',
  styleUrls: ['./docs-details.component.css']
})
export class DocsDetailsComponent implements OnInit {

  constructor(private data : ServicesService, private activatedRoute : ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((val) => {
      console.log("val is ", val);

      this.data.post('transaction/getDocs', {id : val.id}, 0).subscribe((res : any) => {
        console.log(">>>>>>>>>> ", res.results);
      });
    });
  }

}
