import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { ServicesService } from 'src/app/services.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  categoryList: any;
  menuList: any;
  sub_menuList: any;
  otplogin: any;
  menuName: any;
  userName: any;

  constructor(
    private globalService: ServicesService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,

  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.otplogin = localStorage.getItem('otpLogin');
        this.userName = localStorage.getItem('user_name');
      }
    });

  }

  ngOnInit() {
    this.globalService.currentLogin.subscribe(arg => this.otplogin = arg);

    this.otplogin = localStorage.getItem('otpLogin');
    this.userName = localStorage.getItem('user_name');
    this.category();
  }



  category() {

    this.globalService.get('addcategories/menu-list', 0).subscribe(res => {
      if (res) {
        this.menuList = res.services;
        this.menuName = res.services._id;

        this.menuList.sort((a,b) => {
          if(a._id.sort_count > b._id.sort_count)return 1;
          if(a._id.sort_count < b._id.sort_count)return -1;

          return 0
        })
      }
    });
  }

  goTo(value) {
    console.log(value);
    console.log(encodeURI(value));
    this.router.navigate([encodeURI(value)]);
  }

}
