import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BusinessServicePlansComponent } from './pages/business-service-plans/business-service-plans.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { ThankuComponent } from './pages/thanku/thanku.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CommonRouteComponent } from './pages/common-route/common-route.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { UpdateDocumentsComponent } from './pages/update-documents/update-documents.component';
import { DocsDetailsComponent } from './docs-details/docs-details.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  {path:'', component:HomePageComponent},
  {path:'privacy', component:PrivacyComponent},
  {path:'terms', component:TermsComponent},
  {path:':seo_url', component:CommonRouteComponent},
  {path:'login', component:LoginComponent},
  {path:'about', component:AboutComponent},
  {path:'contact', component:ContactComponent},
  {path:'order-summary', component:OrderSummaryComponent},
  {path:'thanku', component:ThankuComponent},
  {path:'profile', component:ProfileComponent},
  {path:'invoice/:id', component:InvoiceComponent},
  {path:'update-documents/:id', component:UpdateDocumentsComponent},
  {path:'showdocs/:id', component:DocsDetailsComponent},
  {path: '**',component:CommonRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
