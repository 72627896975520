import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  data: any;
  otp: any;
  message: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  userId: any;
  submitted = false;
  constructor(

    private globalService: ServicesService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location

  ) { }

  ngOnInit() {

  }

  loginForm = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    otp: new FormControl('',),
  })
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }


    if (this.message) {

      const data = {
        number: this.loginForm.value.number,
        otp: this.otp
      }

      this.globalService.post('login/verifyOtp', data, 0).subscribe(res => {
        if (res) {
          console.log(res);
          this.message = res.OTP;

          if (res.ResponseCode === 1) {
            localStorage.setItem('otpLogin', res.number);


            // this.router.navigate();
            this._location.back();

          } else {
            alert('Please Login First !!');
          }
        }

      }, (error: any) => {
        alert('Sorry, Your Account has been blocked !!');
      });


    } else {

      const data = {
        number: this.loginForm.value.number
      }

      this.globalService.post('login', data, 0).subscribe(res => {
        if (res) {
          console.log(res);
          localStorage.setItem('userId', res.userId[0]._id);
          this.message = res.OTP;
          // alert(this.message);
        }
      });

    }
  }

  config = {
    allowNumbersOnly: false,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'color': '#747474'
    }
  };

  onOtpChange(otp) {
    this.otp = otp;
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);

  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

}
