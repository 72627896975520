import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
declare var jQuery: any;
import { ServicesService } from "src/app/services.service";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-thanku",
  templateUrl: "./thanku.component.html",
  styleUrls: ["./thanku.component.css"],
})
export class ThankuComponent implements OnInit {
  documetsKey: any;
  transIid: any;

  constructor(
    private globalService: ServicesService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.documetsKey = localStorage.getItem("documents_status");
        this.transIid = localStorage.getItem("trans_id");
        console.log("this.transIid >> ", this.transIid);
      }
    });
  }

  ngOnInit() {
    this.transIid = localStorage.getItem("trans_id");
    console.log("this.transIid >> ", this.transIid);
    this.documetsKey = localStorage.getItem("documents_status");
  }

  detailsForm = new FormGroup({
    documets_number: new FormControl("", []),
    documets_name: new FormControl("", []),
    documets_message: new FormControl("", []),
  });

  onSubmit() {
    const detailsForm = this.detailsForm.value;
    (detailsForm._id = localStorage.getItem("trans_id")),
      //     const userdetailsData = {
      //       _id : localStorage.getItem('trans_id'),
      //       name:  this.detailsForm.value.name,
      //       number: this.detailsForm.value.number,
      //       message: this.detailsForm.value.message,
      // }

      console.log(detailsForm);
    this.globalService
      .post("transaction/update", detailsForm, 0)
      .subscribe((res) => {
        if (res) {
          console.log("update", res);
          if (res.ResponseCode === "1") {
            this.detailsForm.reset();
            jQuery("#submitDetailsForm").modal("hide");
            this.router.navigate(["/profile"]);
          } else {
            alert("Please Try Again !!");
          }
        }
      });
  }
}
