import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  order : any = {};
  user : any = {};
  trans : any = {};
  stateCode = "";

  constructor(private activatedRoute : ActivatedRoute, private globalService: ServicesService) {
  }

  ngOnInit() {
    // window.print();

    this.activatedRoute.params.subscribe((val) => {
      let transId = this.activatedRoute.snapshot.params.id;

      this.globalService.post("orders/findOrder", {transId : transId}, 0).subscribe((res : any) => {
        if(res.success){
          this.order = res.data[0];
          this.user = this.order.userInfo[0];
          this.trans = this.order.transInfo[0];

          this.globalService.post("add-state/getState", {state : this.trans.state}, 0).subscribe((res : any) => {
            this.stateCode = res.data;
          })
        }
      })
    })
  }



}
