import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  // baseUrl = 'http://localhost:5000/';
  baseUrl = "https://ontaxco.com:5000/";

  xAccessToken: any;
  userToken: string;
  window: any;

  isLogin = JSON.parse(localStorage.getItem('otpLogin'));
  private loginSource = new BehaviorSubject(this.isLogin);
  currentLogin = this.loginSource.asObservable();

  constructor(
    private http: HttpClient,
    private metaService: Meta,
    private titleService: Title,
    private metaTagService: Meta,
    private meta: Meta
  ) {
    this.xAccessToken = localStorage.getItem("token");
    this.userToken = localStorage.getItem("userToken");
  }

  changeLogin(isLogin: boolean) {
    this.loginSource.next(isLogin)
  }

  postGoogle(url, data, isHeader): Observable<any> {
    let httpOptions;
    if (isHeader === 0) {
      return this.http.post(url, data);
    }
  }

  post(endPoint, data, isHeader): Observable<any> {
    let httpOptions;
    if (isHeader === 0) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };

      return this.http.post(this.baseUrl + endPoint, data, httpOptions);
    } else if (isHeader === 1) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          userToken: localStorage.getItem("userToken"),
          currentUrl: window.location.href,
        }),
      };

      return this.http.post(this.baseUrl + endPoint, data, httpOptions);
    }
  }
  /********************************* GET METHOD X-ACCESS-TOKEN *******************************************/

  get(endPoint, isHeader): Observable<any> {
    let httpOptions;
    if (isHeader === 0) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };

      // console.log(JSON.stringify(endPoint));
      // console.log(httpOptions);
      return this.http.get<any>(this.baseUrl + endPoint, httpOptions);
    } else if (isHeader === 1) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          userToken: localStorage.getItem("userToken"),
          currentUrl: window.location.href,
        }),
      };
      return this.http.get<any>(this.baseUrl + endPoint, httpOptions);
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          userToken: localStorage.getItem("userToken"),
        }),
      };
      return this.http.get<any>(this.baseUrl + endPoint, httpOptions);
    }
  }

  postFile(fileToUpload: File): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("myfile", fileToUpload, fileToUpload.name);
    return this.http.post<any>(`${this.baseUrl}login/uploadShipdoc`, formData);
  }

  uploadDoc(url, data, files): Observable<any> {
    const formData: FormData = new FormData();

    for(let i=0;i<files.length;i++){
      formData.append("images", files[i][0]);
    }

    formData.append("id", data.id);
    formData.append("docDescription", data.docDescription);
    formData.append("uploaded_by", "user");

    return this.http.post<any>(`${this.baseUrl}${url}`, formData);
  }

  getProfileImage(id): Observable<boolean> {
    let obj: Object = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      reportProgress: true,
      responseType: "blob",
    };
    return this.http.get<any>(`${this.baseUrl}login/getImage/${id}`, obj);
  }

  SEOService(res) {
    var hostname = "https://ontaxco.com";
    let title = ""

    if(res.seo_title){
      title = res.seo_title;
    }
    else{
      title = res.service_name;
    }

    this.titleService.setTitle(title);

    this.meta.updateTag({ name: "keywords", content: res.seo_keyword });
    this.meta.updateTag({ name: "description", content: res.seo_description });

    this.meta.addTag({ name: "og:title", content: res.service_name });
    this.meta.addTag({
      name: "og:description",
      content: res.seo_description,
    });
    this.meta.addTag({
      name: "og:image",
      content: res.seo_image || res.image,
    });
    this.meta.addTag({
      name: "og:url",
      content: hostname + "/" + res.seo_url,
    });

    this.meta.addTag({ name: "twitter:title", content: res.service_name });
    this.meta.addTag({
      name: "og:description",
      content: res.seo_description,
    });
    this.meta.addTag({
      name: "og:url",
      content: hostname + "/" + res.seo_url,
    });
  }
}
